import React, { useState } from "react";

import { FaTimes } from "react-icons/fa";

import "./NewNav.css";
import { Link } from "react-router-dom";
import "./test.css";
import { useContext } from "react";

const Footer = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  return (
    <>
      <footer class="footer-wrapper footer-layout1">
        <div class="widget-area">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-md-6 col-xl-3">
                <div class="widget footer-widget">
                  <div class="ot-widget-about">
                    <div class="about-logo">
                      <a href="index.php">
                        <img
                          src="https://zarichtravels.com/assets/img/logo-white.png"
                          alt="Zarich"
                        />
                      </a>
                    </div>
                    <p class="about-text">
                      At Zarich, we revolutionize business travel. We're
                      committed to leveraging cutting-edge technology to
                      optimize itineraries, enhance efficiency, and provide
                      unparalleled service.
                    </p>
                    <div class="ot-social">
                      <a href="https://www.facebook.com/zarichtravels">
                        <i class="fab fa-facebook-f"></i>
                      </a>

                      <a href="https://www.linkedin.com/company/zarich-travels">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a href="https://www.instagram.com/zarichtravels">
                        <i class="fab fa-instagram"></i>
                      </a>
                      <a href="https://wa.me/+2349052437474">
                        <i class="fab fa-whatsapp"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget widget_nav_menu footer-widget">
                  <h3 class="widget_title">Quick Links</h3>
                  <div class="menu-all-pages-container">
                    <ul class="menu">
                      <li>
                        <a href="about.php">About Us</a>
                      </li>
                      <li>
                        <a href="tour.php">Tour</a>
                      </li>
                      <li>
                        <a href="visa.php">Visas</a>
                      </li>
                      <li>
                        <a href="affiliate.php">Affiliate</a>
                      </li>
                      <li>
                        <a href="contact.php">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget footer-widget">
                  <h3 class="widget_title">Contact Us</h3>
                  <div class="ot-widget-contact">
                    <div class="info-box">
                      <div class="info-box_icon">
                        <i class="fas fa-location-dot"></i>
                      </div>
                      <p class="info-box_text">
                        Suite i205 Ogba Central Mall, Lagos
                      </p>
                    </div>
                    <div class="info-box">
                      <div class="info-box_icon">
                        <i class="fas fa-phone"></i>
                      </div>
                      <p class="info-box_text">
                        <a href="tel:+2348060204424" class="info-box_link">
                          +234 806 020 4424
                        </a>
                        <a href="tel:+2349052437474" class="info-box_link">
                          +234 905 243 7474
                        </a>
                      </p>
                    </div>
                    <div class="info-box">
                      <div class="info-box_icon">
                        <i class="fas fa-envelope"></i>
                      </div>
                      <p class="info-box_text">
                        <a
                          href="mailto:info@zarichtravels.com"
                          class="info-box_link"
                        >
                          info@zarichtravels.com
                        </a>
                        <a
                          href="mailto:zarichtravels@gmail.com"
                          class="info-box_link"
                        >
                          zarichtravels@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget footer-widget">
                  <h3 class="widget_title">Our Partners</h3>
                  <div class="sidebar-gallery">
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p1.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="assets/img/gen/p1.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p2.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/p2.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p3.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/p3.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p4.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/p4.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p5.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/p5.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/natop.jpg"
                        alt="Gallery Image"
                        style={{ height: "80%" }}
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/natop.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-wrap style2">
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-lg-6">
                <p class="copyright-text">
                  Copyright <i class="fal fa-copyright"></i> 2023{" "}
                  <a href="index.php">Zarich</a>. All Rights Reserved.
                </p>
              </div>
              <div class="col-lg-6 text-end d-none d-lg-block">
                <div class="footer-links">
                  <ul>
                    <li>
                      <a href="terms_condition.php">Terms of Use</a>
                    </li>
                    <li>
                      <a href="privacy.php">Privacy Environmental Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
