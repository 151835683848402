import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import useFetch from "../hooks/useFetch";
import { useLocation, Link, useHistory, useNavigate } from "react-router-dom";
import {
  FaUser,
  FaCalendarAlt,
  FaTag,
  FaArrowRight,
  FaEnvelope,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaGlobe,
  FaPencilAlt,
  FaSearch,
} from "react-icons/fa";

import Footer from "./NewNav";
import "./Blog.css";
import axios from "axios";

const NewsDet = () => {
  const location = useLocation();
  const slug = location.pathname.split("/")[2];
  const { data, loading, error } = useFetch(`/blog/find-by-slug/${slug}`);

  // const formattedDate = data?.datePosted
  //   ? new Date(data.datePosted).toLocaleDateString()
  //   : null;
  const formattedDate = data?.datePosted
    ? new Date(data.datePosted).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
    : null;

  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    if (data && data.title) {
      document.title = data.title;
    }
  }, [data]);
  return (
    <>
      <div
        className="breadcumb-wrapper"
        data-bg-src="https://zarichtravels.com/assets/img/gen/imgs.png"
      >
        <div className="container" style={{ margin: "none" }}>
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">{data?.title}</h1>
            <ul className="breadcumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Blog Details</li>
            </ul>
          </div>
        </div>
      </div>

      <section class="ot-blog-wrapper blog-details space-top space-extra-bottom">
        <div class="container">
          <div class="row">
            <div class="col-xxl-8 col-lg-7">
              <div class="ot-blog blog-single">
                <div class="blog-img">
                  <img src={data.photos} alt="Blog Image" />
                </div>
                <div class="blog-content">
                  <div class="blog-meta">
                    <a href="blog-details.html">
                      <FaUser />
                      {data?.authorName}
                    </a>
                    <a>
                      <FaCalendarAlt /> {formattedDate}
                    </a>
                    <a>
                      <FaTag />
                      Travel
                    </a>
                  </div>
                  <h2 class="blog-title">{data?.title}</h2>

                  <p dangerouslySetInnerHTML={{ __html: data.desc1 }}></p>
                </div>
                <div class="share-links clearfix ">
                  <div class="row justify-content-between">
                    <div class="col-md-auto">
                      <span class="share-links-title">Tags:</span>
                      <div class="tagcloud">
                        <a>Travel</a>
                        <a>Online</a>
                      </div>
                    </div>
                    <div class="col-md-auto text-xl-end">
                      <span class="share-links-title">Share:</span>
                      <ul class="social-links">
                        <li>
                          <a href="https://facebook.com/" target="_blank">
                            <FaFacebookF />
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/" target="_blank">
                            <FaTwitter />
                          </a>
                        </li>
                        <li>
                          <a href="https://linkedin.com/" target="_blank">
                            <FaLinkedinIn />
                          </a>
                        </li>
                        <li>
                          <a href="https://instagram.com/" target="_blank">
                            <FaInstagram />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ot-comment-form ">
                <div class="form-title">
                  <h3 class="blog-inner-title mb-0">Leave a Comment</h3>
                  <p class="form-text">
                    Your email address will not be published. Required fields
                    are marked *
                  </p>
                </div>
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      placeholder="Your Name*"
                      class="form-control"
                    />
                    <FaUser />
                  </div>
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      placeholder="Your Email*"
                      class="form-control"
                    />
                    <FaEnvelope />
                  </div>
                  <div class="col-12 form-group">
                    <input
                      type="text"
                      placeholder="Website"
                      class="form-control"
                    />
                    <FaGlobe />
                  </div>
                  <div class="col-12 form-group">
                    <textarea
                      placeholder="Write a Comment*"
                      class="form-control"
                    ></textarea>
                    <FaPencilAlt />
                  </div>
                  <div class="col-12 form-group mb-0">
                    <button class="ot-btn">Post Comment</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-lg-5">
              <aside class="sidebar-area">
                <div class="widget widget_search   ">
                  <form class="search-form">
                    <input type="text" placeholder="Enter Keyword" />
                    <button type="submit">
                      <FaSearch />
                    </button>
                  </form>
                </div>
                <div class="widget widget_categories  ">
                  <h3 class="widget_title">Categories</h3>
                  <ul>
                    <li>
                      <a>Food</a>
                    </li>
                    <li>
                      <a>Travel</a>
                    </li>
                    <li>
                      <a>Life Style</a>
                    </li>
                    <li>
                      <a>Music</a>
                    </li>
                    <li>
                      <a>Games/Sport</a>
                    </li>
                  </ul>
                </div>
                <div class="widget  ">
                  <h3 class="widget_title">Recent Posts</h3>
                  <div class="recent-post-wrap">
                    <div class="recent-post">
                      <div class="media-img">
                        <a>
                          <img src={data.photos} alt="Blog Image" />
                        </a>
                      </div>
                      <div class="media-body">
                        <h4 class="post-title">
                          <a class="text-inherit">{data.title}</a>
                        </h4>
                        <div class="recent-post-meta">
                          <a>
                            <FaCalendarAlt />
                            {formattedDate}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="widget widget_tag_cloud  ">
                  <h3 class="widget_title">Popular Tags</h3>
                  <div class="tagcloud">
                    <a>Adventure</a>
                    <a>Beach</a>
                    <a>Destinations</a>
                    <a>Parks</a>
                    <a>Lifestyle</a>
                    <a>Tourisms</a>
                    <a>Hotels</a>
                    <a>Transport</a>
                    <a>Tourist</a>
                  </div>
                </div>
                <div class="widget widget_banner  ">
                  <div class="offer-banner">
                    <div class="banner-logo">
                      <img src="assets/img/logo-white.svg" alt="Travon" />
                    </div>
                    <span class="banner-subtitle">Happy Holiday</span>
                    <h3 class="banner-title">Connect with us</h3>
                    <div class="offer">
                      <h6 class="offer-title">
                        <span class="text-theme">Travon</span> Special
                      </h6>
                      <p class="offer-text">
                        <span class="text-theme">30% off</span> On All Booking
                      </p>
                    </div>
                    <a href="contact.html" class="ot-btn">
                      Connect with us
                    </a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <section
        className=""
        data-pos-for=".footer-wrapper"
        data-sec-pos="bottom-half"
      >
        <div className="container">
          <div
            className="newsletter-wrap"
            data-bg-src="https://zarichtravels.com/assets/img/bg/subscribe_bg_1.png"
          >
            <h2 className="sec-title text-white mb-2">
              Special offers and more from Zarich
            </h2>
            <p className="text-white fs-md mb-4">
              Sign up now and get the best deals straight in your inbox!
            </p>
            <form className="newsletter-form">
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email Address"
                  required=""
                />
                <FaEnvelope />
              </div>
              <button type="submit" className="ot-btn">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </section>

      <Footer />

      <div className="scroll-top">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
        </svg>
      </div>
    </>
  );
};

export default NewsDet;
