import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import useFetch from "../hooks/useFetch";
import Footer from "./NewNav";
import "./Blog.css";
import axios from "axios";
import DOMPurify from "dompurify";

import {
  FaUser,
  FaCalendarAlt,
  FaTag,
  FaArrowRight,
  FaEnvelope,
} from "react-icons/fa";

const Blog = () => {
  const { data, loading, error } = useFetch("/blog?featured=true");
  console.log("this is data place", data);
  const [blogs, setBlogs] = useState([]);
  const [showFullContent, setShowFullContent] = useState(false);

  // useEffect(() => {
  //   const fetchBlogs = async () => {
  //     try {
  //       const response = await axios.get(`${apiUrl}api/blog`);
  //       setBlogs(response.data);
  //     } catch (err) {
  //       setError(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchBlogs();
  // }, []);

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error: {error.message}</p>;
  // }
  const handleReadMoreClick = () => {
    setShowFullContent(true);
  };
  // const handleReadMoreClick = (slug) => {
  //   // Navigate to the blog details page
  //   window.location.href = `/singleblog/${slug}`;
  // };
  useEffect(() => {
    if (data && data.length > 0) {
      const sortedBlogs = data.sort(
        (a, b) => new Date(b.datePosted) - new Date(a.datePosted)
      );
      setBlogs(sortedBlogs);
    }
  }, [data]);
  return (
    <>
      <div
        className="breadcumb-wrapper"
        data-bg-src="https://zarichtravels.com/assets/img/gen/imgs.png"
      >
        <div className="container" style={{ margin: "none" }}>
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">Our Blog</h1>
            <ul className="breadcumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Our Blog</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="ot-blog-wrapper space-top space-extra-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-lg-7">
              {data.map((blog) => (
                <div
                  key={blog._id}
                  className="ot-blog blog-single has-post-thumbnail"
                >
                  <div className="blog-img">
                    <a href={`/singleblog/${blog.slug}`}>
                      <img src={blog?.photos[0]} alt="Blog Image" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <div className="blog-meta">
                      <a href="#">
                        <FaUser />
                        {blog?.authorName}
                      </a>
                      <a href="#">
                        <FaCalendarAlt />
                        {new Date(blog?.datePosted).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                          }
                        )}
                      </a>

                      <a href="#">
                        <FaTag />
                        Travel
                      </a>
                    </div>
                    <h2 className="blog-title">
                      <a href={`/singleblog/${blog?.slug}`}>{blog.title}</a>
                    </h2>

                    <p>
                      {showFullContent ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(blog?.desc1),
                          }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              `${blog?.desc1.slice(0, 150)}...`
                            ),
                          }}
                        />
                      )}
                    </p>
                    <a href={`/singleblog/${blog?.slug}`} className="ot-btn">
                      Read More
                      <FaArrowRight />
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-xxl-4 col-lg-5">
              <aside className="sidebar-area">
                <div className="widget widget_categories">
                  <h3 className="widget_title">Categories</h3>
                  <ul>
                    <li>
                      <a>Food</a>
                    </li>
                    <li>
                      <a>Travel</a>
                    </li>
                    <li>
                      <a>Life Style</a>
                    </li>
                    <li>
                      <a>Music</a>
                    </li>
                    <li>
                      <a>Games/Sport</a>
                    </li>
                  </ul>
                </div>

                <div className="widget widget_tag_cloud">
                  <h3 className="widget_title">Popular Tags</h3>
                  <div className="tagcloud">
                    <a>Adventure</a>
                    <a>Beach</a>
                    <a>Destinations</a>
                    <a>Parks</a>
                    <a>Lifestyle</a>
                    <a>Tourism</a>
                    <a>Hotels</a>
                    <a>Transport</a>
                    <a>Tourist</a>
                  </div>
                </div>

                <div className="widget widget_banner">
                  <div className="offer-banner">
                    <div className="banner-logo">
                      <img src="assets/img/logo-white.png" alt="Zarich" />
                    </div>
                    <span className="banner-subtitle">Happy Holiday</span>
                    <h3 className="banner-title">Connect with us</h3>
                    <div className="offer">
                      <h6 className="offer-title">
                        <span className="text-theme">Zarich</span> Special
                      </h6>
                      <p className="offer-text">
                        <span className="text-theme">Special Discount</span> On
                        All Booking
                      </p>
                    </div>
                    <a href="contact.php" className="ot-btn">
                      Connect with us
                    </a>
                  </div>
                </div>

                <img
                  style={{ marginTop: "10px" }}
                  src="https://zarichtravels.com/assets/img/gen/b1.jpg"
                  alt="Zarich"
                />
                <img
                  style={{ marginTop: "10px" }}
                  src="https://zarichtravels.com/assets/img/gen/b2.jpg"
                  alt="Zarich"
                />
                <img
                  style={{ marginTop: "10px" }}
                  src="https://zarichtravels.com/assets/img/gen/b3.jpg"
                  alt="Zarich"
                />
                <img
                  style={{ marginTop: "10px" }}
                  src="https://zarichtravels.com/assets/img/gen/b4.jpg"
                  alt="Zarich"
                />
              </aside>
            </div>
          </div>
        </div>
      </section>

      <section
        className=""
        data-pos-for=".footer-wrapper"
        data-sec-pos="bottom-half"
      >
        <div className="container">
          <div
            className="newsletter-wrap"
            data-bg-src="https://zarichtravels.com/assets/img/bg/subscribe_bg_1.png"
          >
            <h2 className="sec-title text-white mb-2">
              Special offers and more from Zarich
            </h2>
            <p className="text-white fs-md mb-4">
              Sign up now and get the best deals straight in your inbox!
            </p>
            <form className="newsletter-form">
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email Address"
                  required=""
                />
                <FaEnvelope />
              </div>
              <button type="submit" className="ot-btn">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </section>

      <Footer />

      <div className="scroll-top">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
        </svg>
      </div>
    </>
  );
};

export default Blog;
