import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import "./Blog.css";

const Tour = () => {
  const { data, loading, error } = useFetch("/properties?featured=true");

  return (
    <>
      <div class="sidemenu-wrapper d-none d-lg-block ">
        <div class="sidemenu-content">
          <button class="closeButton sideMenuCls">
            <i class="far fa-times"></i>
          </button>
          <div class="widget footer-widget">
            <div class="ot-widget-about">
              <div class="about-logo">
                <a href="index.php">
                  <img src="assets/img/zarr.png" alt="Zarich" />
                </a>
              </div>
              <p class="about-text">
                Zarich Travels reflects the perfect blend of global expertise
                and local knowledge. Right from travel planning to ticketing
                services, hotel bookings, global visa process and organizing
                activities at destinations around the world.
              </p>
              <div class="ot-social">
                <a href="https://www.facebook.com/">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.twitter.com/">
                  <i class="fab fa-twitter"></i>
                </a>
                <a href="https://www.linkedin.com/">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a href="https://www.instagram.com/">
                  <i class="fab fa-instagram"></i>
                </a>
                <a href="https://www.whatsapp.com/">
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="widget footer-widget">
            <h3 class="widget_title">Recent Posts</h3>
            <div class="recent-post-wrap">
              <div class="recent-post">
                <div class="media-img">
                  <a href="blog-details.php">
                    <img
                      src="https://zarichtravels.com/assets/img/blog/recent-post-2-1.jpg"
                      alt="Blog Image"
                    />
                  </a>
                </div>
                <div class="media-body">
                  <h4 class="post-title">
                    <a class="text-inherit" href="blog-details.php">
                      5 Ways to Get Your Dream Photos On Picnic
                    </a>
                  </h4>
                  <div class="recent-post-meta">
                    <a href="blog.php">
                      <i class="fal fa-calendar-days"></i>21 June, 2023
                    </a>
                  </div>
                </div>
              </div>
              <div class="recent-post">
                <div class="media-img">
                  <a href="blog-details.php">
                    <img
                      src="https://zarichtravels.com/assets/img/blog/recent-post-2-2.jpg"
                      alt="Blog Image"
                    />
                  </a>
                </div>
                <div class="media-body">
                  <h4 class="post-title">
                    <a class="text-inherit" href="blog-details.php">
                      9 Essential Tips For Making the Most of Your
                    </a>
                  </h4>
                  <div class="recent-post-meta">
                    <a href="blog.php">
                      <i class="fal fa-calendar-days"></i>22 June, 2023
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widget footer-widget">
            <h3 class="widget_title">Contact Us</h3>
            <div class="ot-widget-contact">
              <div class="info-box">
                <div class="info-box_icon">
                  <i class="fas fa-location-dot"></i>
                </div>
                <p class="info-box_text">
                  Suite i205 Ogba Central Mall, Lagos.
                </p>
              </div>
              <div class="info-box">
                <div class="info-box_icon">
                  <i class="fas fa-phone"></i>
                </div>
                <p class="info-box_text">
                  <a href="tel:+2348060204424" class="info-box_link">
                    +234 806 020 4424
                  </a>
                  <a href="tel:+2349052437474" class="info-box_link">
                    +234 905 243 7474
                  </a>
                </p>
              </div>
              <div class="info-box">
                <div class="info-box_icon">
                  <i class="fas fa-envelope"></i>
                </div>
                <p class="info-box_text">
                  <a href="mailto:info@Zarich.com" class="info-box_link">
                    info@zarichtravels.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ot-menu-wrapper">
        <div class="ot-menu-area text-center">
          <button class="ot-menu-toggle">
            <i class="fal fa-times"></i>
          </button>
          <div class="mobile-logo">
            <a href="index.php">
              <img
                src="https://zarichtravels.com/assets/img/logo.png"
                alt="Zarich"
              />
            </a>
          </div>
          <div class="ot-mobile-menu">
            <ul>
              <li>
                <a href="index.php">Home</a>
              </li>
              <li>
                <a href="about.php">About Us</a>
              </li>
              <li class="menu-item-has-children">
                <a href="#">Services</a>
                <ul class="sub-menu">
                  <li>
                    <a href="tour.php">Tour</a>
                  </li>
                  <li>
                    <a href="visa.php">Visa</a>
                  </li>
                  <li>
                    <a href="cargo.php">Cargo</a>
                  </li>
                  <li>
                    <a href="car.php">Car Rental</a>
                  </li>
                  <li>
                    <a href="study.php">Study Abroad</a>
                  </li>
                  <li>
                    <a href="faq.php">FAQ</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="corporate.php">Corporate Travel</a>
              </li>
              <li>
                <a href="affiliate.php">Affiliate</a>
              </li>
              <li>
                <a href="blog.php">Blog</a>
              </li>
              <li>
                <a href="contact.php">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <header class="ot-header header-layout1">
        <div class="header-top">
          <div class="container">
            <div class="row justify-content-center justify-content-lg-between align-items-center">
              <div class="col-auto d-none d-lg-block">
                <div class="header-links">
                  <ul>
                    <li>
                      <i class="fal fa-phone"></i>
                      <a href="tel:+2348060204424">+2348060204424</a>
                    </li>
                    <li>
                      <i class="fal fa-phone"></i>
                      <a href="tel:+2349052437474">+2349052437474</a>
                    </li>
                    <li class="d-none d-xl-inline-block">
                      <i class="fal fa-envelope"></i>
                      <a href="mailto:info@zarichtravels.com">
                        info@zarichtravels.com
                      </a>
                    </li>
                    <li>
                      <i class="fal fa-location-dot"></i>Suite i205 Ogba Central
                      Mall, Lagos
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-auto">
                <div class="header-links">
                  <ul>
                    <li>
                      <div class="header-social">
                        <span class="social-title">Follow Us:</span>
                        <a href="https://www.facebook.com/">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://www.twitter.com/">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sticky-wrapper">
          <div class="menu-area">
            <div class="container">
              <div class="row align-items-center justify-content-between">
                <div class="col-auto">
                  <div class="header-logo">
                    <a href="index.php">
                      <img
                        src="https://zarichtravels.com/assets/img/logo.png"
                        alt="Zarich"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-auto">
                  <nav class="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li>
                        <a href="index.php">Home</a>
                      </li>
                      <li>
                        <a href="about.php">About Us</a>
                      </li>
                      <li class="menu-item-has-children">
                        <a href="#">Services</a>
                        <ul class="sub-menu">
                          <li>
                            <a href="tour.php">Tour</a>
                          </li>
                          <li>
                            <a href="visa.php">Visa</a>
                          </li>
                          <li>
                            <a href="cargo.php">Cargo</a>
                          </li>
                          <li>
                            <a href="car.php">Car Rental</a>
                          </li>
                          <li>
                            <a href="study.php">Study Abroad</a>
                          </li>
                          <li>
                            <a href="faq.php">FAQ</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="corporate.php">Corporate Travel</a>
                      </li>
                      <li>
                        <a href="affiliate.php">Affiliate</a>
                      </li>
                      <li>
                        <a href="blog.php">Blog</a>
                      </li>
                      <li>
                        <a href="contact.php">Contact Us</a>
                      </li>
                    </ul>
                  </nav>
                  <button
                    type="button"
                    class="ot-menu-toggle d-inline-block d-lg-none"
                  >
                    <i class="far fa-bars"></i>
                  </button>
                </div>
                <div class="col-auto d-none d-xl-block">
                  <div class="header-button">
                    <a href="#" class="icon-btn sideMenuToggler">
                      <i class="fa-regular fa-bars"></i>
                    </a>
                    <a href="contact.php" class="ot-btn ml-25">
                      Book Your Stay
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="logo-bg"></div>
          </div>
        </div>
      </header>

      <div
        class="breadcumb-wrapper "
        data-bg-src="https://zarichtravels.com/assets/img/gen/imgs.png"
      >
        <div class="container" style={{ margin: "none" }}>
          <div class="breadcumb-content">
            <h1 class="breadcumb-title">Tour Packages</h1>
            <ul class="breadcumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Tour Packages</li>
            </ul>
          </div>
        </div>
      </div>
      <section
        class=" "
        data-pos-for=".footer-wrapper"
        data-sec-pos="bottom-half"
      >
        <div class="container">
          <div
            class="newsletter-wrap"
            data-bg-src="https://zarichtravels.com/assets/img/bg/subscribe_bg_1.svg"
          >
            <h2 class="sec-title text-white mb-2">
              Special offers and more from Zarich
            </h2>
            <p class="text-white fs-md mb-4">
              Sign up now and get the best deals straight in your inbox!
            </p>
            <form class="newsletter-form">
              <div class="form-group">
                <input
                  class="form-control"
                  type="email"
                  placeholder="Email Address"
                  required=""
                />
                <i class="fal fa-envelope"></i>
              </div>
              <button type="submit" class="ot-btn">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </section>

      <footer class="footer-wrapper footer-layout1">
        <div class="widget-area">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-md-6 col-xl-3">
                <div class="widget footer-widget">
                  <div class="ot-widget-about">
                    <div class="about-logo">
                      <a href="index.php">
                        <img
                          src="https://zarichtravels.com/assets/img/logo-white.png"
                          alt="Zarich"
                        />
                      </a>
                    </div>
                    <p class="about-text">
                      At Zarich, we revolutionize business travel. We're
                      committed to leveraging cutting-edge technology to
                      optimize itineraries, enhance efficiency, and provide
                      unparalleled service.
                    </p>
                    <div class="ot-social">
                      <a href="https://www.facebook.com/zarichtravels">
                        <i class="fab fa-facebook-f"></i>
                      </a>

                      <a href="https://www.linkedin.com/company/zarich-travels">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a href="https://www.instagram.com/zarichtravels">
                        <i class="fab fa-instagram"></i>
                      </a>
                      <a href="https://wa.me/+2349052437474">
                        <i class="fab fa-whatsapp"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget widget_nav_menu footer-widget">
                  <h3 class="widget_title">Quick Links</h3>
                  <div class="menu-all-pages-container">
                    <ul class="menu">
                      <li>
                        <a href="about.php">About Us</a>
                      </li>
                      <li>
                        <a href="tour.php">Tour</a>
                      </li>
                      <li>
                        <a href="visa.php">Visas</a>
                      </li>
                      <li>
                        <a href="affiliate.php">Affiliate</a>
                      </li>
                      <li>
                        <a href="contact.php">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget footer-widget">
                  <h3 class="widget_title">Contact Us</h3>
                  <div class="ot-widget-contact">
                    <div class="info-box">
                      <div class="info-box_icon">
                        <i class="fas fa-location-dot"></i>
                      </div>
                      <p class="info-box_text">
                        Suite i205 Ogba Central Mall, Lagos
                      </p>
                    </div>
                    <div class="info-box">
                      <div class="info-box_icon">
                        <i class="fas fa-phone"></i>
                      </div>
                      <p class="info-box_text">
                        <a href="tel:+2348060204424" class="info-box_link">
                          +234 806 020 4424
                        </a>
                        <a href="tel:+2349052437474" class="info-box_link">
                          +234 905 243 7474
                        </a>
                      </p>
                    </div>
                    <div class="info-box">
                      <div class="info-box_icon">
                        <i class="fas fa-envelope"></i>
                      </div>
                      <p class="info-box_text">
                        <a
                          href="mailto:info@zarichtravels.com"
                          class="info-box_link"
                        >
                          info@zarichtravels.com
                        </a>
                        <a
                          href="mailto:zarichtravels@gmail.com"
                          class="info-box_link"
                        >
                          zarichtravels@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget footer-widget">
                  <h3 class="widget_title">Our Partners</h3>
                  <div class="sidebar-gallery">
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p1.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="assets/img/gen/p1.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p2.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/p2.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p3.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/p3.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p4.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/p4.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/p5.jpg"
                        alt="Gallery Image"
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/p5.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="gallery-thumb">
                      <img
                        src="https://zarichtravels.com/assets/img/gen/natop.jpg"
                        alt="Gallery Image"
                        style={{ height: "80%" }}
                      />
                      <a
                        href="https://zarichtravels.com/assets/img/gen/natop.jpg"
                        class="gallery-btn popup-image"
                      >
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-wrap style2">
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-lg-6">
                <p class="copyright-text">
                  Copyright <i class="fal fa-copyright"></i> 2023{" "}
                  <a href="index.php">Zarich</a>. All Rights Reserved.
                </p>
              </div>
              <div class="col-lg-6 text-end d-none d-lg-block">
                <div class="footer-links">
                  <ul>
                    <li>
                      <a href="terms_condition.php">Terms of Use</a>
                    </li>
                    <li>
                      <a href="privacy.php">Privacy Environmental Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div class="scroll-top">
        <svg
          class="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            // style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 307.919;"
          ></path>
        </svg>
      </div>
    </>
  );
};

export default Tour;
