import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import News from "./pages/News";
import NewsDet from "./pages/NewsDet";
import Blog from "./pages/Blog";
import Tour from "./pages/Tour";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Blog />} />
          <Route exact path="/tours" element={<Tour />} />
          <Route exact path="/bog" element={<News />} />
          <Route exact path="/singleblog/:slug" element={<NewsDet />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
